import React from 'react'
import Layout from '../../components/layout'; 
import DangerousHTML from 'react-dangerous-html';
import { graphql } from 'gatsby'; 

const Omos = ({ data }) => {
  const { 
    frontmatter,
    html
  } = data.markdownRemark;
  return (
    <Layout>
      <header data-aos='fade-in' className='page-title'>
        <h1>
          { frontmatter.title }
          <span style={{width: 100, height: 6, margin: '8px auto 0', display: 'block', backgroundColor: '#29699B'}}></span>
        </h1>
      </header>
      <main className='page-content'>
        {html && <DangerousHTML html={html} />}
        <div className='vertical-rule' />
        <img src={frontmatter.image} alt={frontmatter.title} className='product-image' />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: {title: {eq: "Om os"}}) {
    	frontmatter{
        title
        image
      }
      html
    }
  }
`

export default Omos; 